/* @ngInject */
export default function NvQuizQuestion(
) {
  return {
    replace: true,
    scope: {
      question: '=',
      form: '=?',
      hideIndex: '=?',
      viewAnswers: '=?',
      viewFeedback: '=?',
      responses: '=?',
      ngDisabled: '=?',
      quizSubmissionType: '=?',
      editMode: '=?',
    },
    controller: function ctrl($scope, $element, $attrs) {
'ngInject';
      const vm = this;

      vm.onCompile = function () {
        const titleParagraph = $element.find('[nv-compile-always] p');

        let isRequired;

        if (
          vm.question.type === 'TableNumberQuestion'
          || vm.question.type === 'TableShortTextQuestion'
        ) {
          const hasAtLeastOneRequiredQuestion = vm.question.childrenQuestions.some(
            (childrenQuestion) => childrenQuestion.isRequired,
          );

          isRequired = hasAtLeastOneRequiredQuestion;
        } else {
          // eslint-disable-next-line prefer-destructuring
          isRequired = vm.question.isRequired;
        }

        if (isRequired) {
          const hasImageOrIframe = !!titleParagraph.find('img, iframe').length;

          titleParagraph.last().append(`<span class="required-indicator${hasImageOrIframe ? ' block' : ''}">*</span>`);
        }
      };
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'quizzes/templates/nv-quiz-question.html',
  };
}
