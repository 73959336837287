import { css } from '@emotion/react';

import React, { useEffect } from 'react';
import { hexToRgbaString, gray1 } from 'styles/global_defaults/colors';
import t from 'react-translate';
import { Button, Modal } from 'react-bootstrap';
import { standardSpacing, tooltipZIndex } from 'styles/global_defaults/scaffolding';
import { closeConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/schemas';
import { ConfirmationDialog } from 'redux/schemas/app/confirmation-dialog';
import { useAppDispatch } from 'redux/store';
import { getSanitizedStyles } from 'shared/utils';
import Spinner from 'shared/components/spinner';
import { getModalManager } from './nv-modal';

const doubleModalZIndex = 7050;

const styles = css`
  /* Necessary to be even over L4 pages */
  z-index: ${doubleModalZIndex};
  .nv-confirmation-overlay {
    /* TODO: Should we use the existing angular js value for this? */
    position: fixed;
    width: 100vw;
    height: 100vh;
    /* top and left are required here in order to make this overlay on top of the left hand nav */
    top: 0;
    left: 0;
    color: white;
    background-color: ${hexToRgbaString(gray1, 0.95)};
    text-align: center;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;

    padding-top: 20vh;
    padding-left: ${standardSpacing * 2}px;
    padding-right: ${standardSpacing * 2}px;

    .icon {
      margin-bottom: ${standardSpacing * 2}px;
    }

    .title-text, .body-text {
      max-width: 800px;
    }

    .title-text {
      margin-bottom: ${standardSpacing * 2}px;
    }

    .body-text {
      margin-bottom: ${standardSpacing * 3}px;
    }

    .buttons {
      & > *:not(:last-of-type) {
        margin-right: ${standardSpacing}px;
      }
    }

    a {
      color: white;
      text-decoration: underline;
    }
  }
`;

const modalZIndexStyle = css`z-index: ${doubleModalZIndex + 1};`;

export const NvConfirmationOverlay = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const {
    cancelText = t.FORM.CANCEL(),
    confirmText = t.FORM.CONFIRM(),
    icon = 'warning',
    showIcon = true,
    onCancel,
    onConfirm,
    isShown,
    title,
    bodyText,
    footerText,
    confirmButtonVariant,
    confirmButtonDisabled,
    cancelButtonVariant,
    noCancelButton,
    cancelPendoTag,
    confirmPendoTag,
    cancelDataQa,
    confirmDataQa,
    shouldWaitForConfirmation = false,
  } = useSelector<RootState, ConfirmationDialog>((state) => state.app.confirmationDialog);

  const onCancelClicked = (e: React.MouseEvent) => {
    onCancel?.();
    dispatch(closeConfirmationDialog());
  };

  const onConfirmClicked = (e: React.MouseEvent) => {
    onConfirm?.();
    if (shouldWaitForConfirmation) {
      setIsLoading(true);
    } else {
      dispatch(closeConfirmationDialog());
    }
  };

  useEffect(() => {
    if (!isShown) {
      setIsLoading(false);
    }
  }, [isShown]);

  /* A react-bootstrap Modal is used here to force the confirm dialog to appear above
   all other page content */
  return (
    isShown
    && (
      <Modal
        show
        animation={false}
        manager={getModalManager()}
        css={getSanitizedStyles([styles, modalZIndexStyle])}
      >
        <div css={styles} className='nv-confirmation-overlay'>
          {showIcon && <div className={`icon icon-large icon-${icon}`} />}
          {title && <div className='title-text page-title text-center'>{title}</div>}
          {bodyText && (
            <div className='body-text text-large-regular text-center'>
              {bodyText}
              {footerText && <div className='footer-text text-large-regular text-center mt-2'>{footerText}</div>}
            </div>
          )}
          <div className='buttons mb-4'>
            {!noCancelButton && (
              <Button
                variant={cancelButtonVariant ?? 'secondary'}
                className='bs4-night'
                pendo-tag-name={cancelPendoTag}
                data-qa={cancelDataQa}
                onClick={(e) => onCancelClicked(e)}
                disabled={isLoading}
              >
                {cancelText}
              </Button>
            )}
            <Button
              variant={confirmButtonVariant ?? 'danger'}
              className='bs4-night'
              disabled={confirmButtonDisabled || isLoading}
              pendo-tag-name={confirmPendoTag}
              data-qa={confirmDataQa}
              onClick={(e) => onConfirmClicked(e)}
            >
              <div className='ml-auto d-flex align-items-center justify-content-center'>
                {isLoading && (
                  <div className='mr-1 d-flex align-items-center'>
                    <Spinner size={16} />
                  </div>
                )}
                {!isLoading ? confirmText : t.FORM.SAVING()}
              </div>
            </Button>
          </div>
        </div>
      </Modal>
    )
  );
};

export default NvConfirmationOverlay;
