import store from 'redux/store';
import { openConfirmationDialog, changeDisableButtonState, closeConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { isUndefined } from 'underscore';
import { useAngularPreventLecturePageNavigation } from '../../lecture_pages/hooks/use-prevent-lecture-page-navigation';
import t from '../../react-translate';
import DiscussionBody from '../../lecture_pages/components/discussion-body.tsx';

/* @ngInject */
export default function NewPostModalController(
  DiscussionsManager,
  CurrentUserManager,
  $uibModalInstance,
  StateManager,
  $scope,
  post,
  _,
  MentionablesModel,
  CurrentCourseManager,
  ConfirmationOverlays,
  $window,
  $translate,
  PostModel,
  AlertMessages,
  config,
) {
  const vm = this;

  vm.discussionStarter = {
    new: null,
    current: null,
  };
  vm.config = config;
  vm.manager = DiscussionsManager;
  vm.CurrentUserManager = CurrentUserManager;
  vm.currentCourseManager = CurrentCourseManager;
  vm.mentionablesModel = MentionablesModel;
  vm.selectingDate = post && !post.released;
  vm.post = post;
  vm.updatingPost = !!post;
  vm.unsavedChangesMessage = 'FORM.NAVIGATE_AWAY.CLOSE_WINDOW';
  vm.confirmingClose = false;
  vm.submitting = false;
  vm.submittingSuccessful = false;
  vm.shouldBulkUpdateDiscussionStarter = undefined;
  vm.defaultDate = Date.now();

  vm.setInitialTopic = setInitialTopic;

  vm.newPost = {
    title: post ? post.title : '',
    body: post ? post.body : '',
    topic: setInitialTopic(),
    releaseDate: post ? post.releaseDate : vm.defaultDate,
    starterId: post ? post.user.id : vm.CurrentUserManager.user.id,
    id: post ? post.id : null,
    mentionedIds: [],
  };

  if (vm.currentCourseManager.course.teachingTeamMembers) {
    vm.discussionStarterOptions = _.pluck(_.clone(vm.currentCourseManager.course.teachingTeamMembers), 'user');

    // if the original starter isn't already in the list, add
    if (post && !_.findWhere(vm.discussionStarterOptions, { id: post.user.id })) {
      vm.discussionStarterOptions.unshift(post.user);
    }

    // if the current user isn't in the list, add
    if (!_.findWhere(vm.discussionStarterOptions, { id: CurrentUserManager.user.id })) {
      vm.discussionStarterOptions.unshift(vm.CurrentUserManager.user);
    }
  }

  vm.selectTopic = function (topic) {
    vm.newPost.topic = topic;

    vm.newPostForm.$setValidity('selectedTopic', vm.newPost.topic?.id !== 0);
  };


  vm.savePost = async function () {
    // save the mentioned ids first
    vm.newPost.mentionedIds = MentionablesModel.parseMentionables(vm.newPost.body);
    vm.submitting = true;
    const normalizedPostData = _.extend(
      vm.newPost, {
        updating: !!post,
        releaseDate: (vm.newPost.releaseDate === vm.defaultDate ? null : vm.newPost.releaseDate),
        catalogId: DiscussionsManager.catalogId,
        shouldBulkUpdateDiscussionStarter: vm.shouldBulkUpdateDiscussionStarter,
      },
    );

    if (vm.newPost.updating) {
      await PostModel.update(normalizedPostData).then((response) => {
        vm.submittingSuccessful = true;
        $uibModalInstance.close();
        if (vm.shouldBulkUpdateDiscussionStarter) {
          DiscussionsManager.loadPosts();
        } else {
          DiscussionsManager.setSinglePostData(
            PostModel.normalizePostData(response.result, {
              catalogId: DiscussionsManager.catalogId,
              owner: DiscussionsManager.findTopic(response.result.forumId),
            }), true, post.topicId,
          );
        }
      });
    } else {
      // new post
      PostModel.create(normalizedPostData).then((response) => {
        vm.submittingSuccessful = true;
        $uibModalInstance.close();
        DiscussionsManager.addNewPost(PostModel.normalizePostData(response.result, { owner: DiscussionsManager.findTopic(response.result.forumId) }), true);
      }).catch(
        (error) => {
          AlertMessages.error('', 'FORM.ERROR');
          vm.submitting = false;
        },
      );
    }
  };

  vm.submitNewPost = function () {
    if (vm.discussionStarter.new && vm.discussionStarter.new.id !== vm.discussionStarter.current.id) {
      store.dispatch(openConfirmationDialog({
        icon: 'warning',
        title: t.DISCUSSIONS.BULK_STARTER_UPDATE.TITLE(),
        bodyText: <DiscussionBody
          currentDiscussionStarterUser={vm.discussionStarter.current}
          newDiscussionStarterUser={vm.discussionStarter.new}
          onChange={(e) => {
            store.dispatch(changeDisableButtonState(false));
            vm.shouldBulkUpdateDiscussionStarter = e;
          }}
        />,
        confirmText: t.DISCUSSIONS.BULK_STARTER_UPDATE.OK_BUTTON(),
        confirmButtonVariant: 'danger',
        confirmButtonDisabled: isUndefined(vm.shouldBulkUpdateDiscussionStarter),
        onCancel: () => {
          vm.shouldBulkUpdateDiscussionStarter = undefined;
        },
        cancelText: t.DISCUSSIONS.BULK_STARTER_UPDATE.CANCEL_BUTTON(),
        shouldWaitForConfirmation: true,
        onConfirm: () => {
          vm.savePost().finally(() => {
            store.dispatch(closeConfirmationDialog());
          });
        },
      }));
    } else {
      vm.savePost();
    }
  };

  vm.adjustReleaseDateValue = function () {
    if (!vm.selectingDate || (vm.releaseDate === undefined || vm.releaseDate === '')) {
      vm.newPost.releaseDate = null;
    }
  };

  vm.changeDiscussionStarter = function (newDiscussionStarterUser) {
    vm.discussionStarter = {
      new: newDiscussionStarterUser,
      current: vm.post.user,
    };
    vm.newPost.starterId = newDiscussionStarterUser.id;
  };

  vm.findDiscussionStarterById = function (userId) {
    return _.findWhere(vm.discussionStarterOptions, { id: userId });
  };

  vm.filterTopicOptions = function () {
    return _.filter(DiscussionsManager.allTopics, (topic) => topic.id > 0);
  };

  vm.hasUnsavedChanges = function () {
    return vm.newPostForm
      && (vm.newPostForm.$dirty
        || (vm.newPost.topic && vm.post && vm.newPost.topic.id !== vm.post.forumId)
        || (vm.post?.user.id !== vm.newPost.starterId))
      && !vm.submitting && !vm.submittingSuccessful;
  };

  vm.topicSelected = function () {
    return vm.newPost.topic?.id;
  };


  function setInitialTopic() {
    if (post) {
      return DiscussionsManager.findTopic(post.forumId);
    } if (DiscussionsManager.searchParams.selectedTopic.id > 0) {
      return DiscussionsManager.searchParams.selectedTopic;
    }
    return null;
  }

  const checkUnsavedChanges = () => vm.hasUnsavedChanges();

  useAngularPreventLecturePageNavigation($scope, checkUnsavedChanges);

  // warn if leaving the modal and have unsaved changes
  vm.deregisterStateChangeStart = StateManager.registerStateChangeStart(
    checkUnsavedChanges,
    'discussions/templates/nv-unsaved-changes-confirmation-overlay.html',
    vm.unsavedChangesMessage,
  );

  $scope.$on('modal.closing', ($event) => {
    if (vm.hasUnsavedChanges() && !vm.confirmingClose && !vm.submitting) {
      $event.preventDefault();


      const modalOverlay = ConfirmationOverlays.openConfirmationModal('discussions/templates/nv-unsaved-changes-confirmation-overlay.html');

      modalOverlay.result.then(() => {
        vm.confirmingClose = true;
        vm.submitting = false;
        $uibModalInstance.dismiss();
      });
    }
  });

  $scope.$on('$destroy', () => {
    vm.deregisterStateChangeStart?.();
  });
}
